import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet"
import { navigate } from 'gatsby'
import { CheckToken, requestGetWithKey } from '../../../helpers/utils'
import SingleContact from '../../../components/campaigns/singleContacts'
import DashboardLayout from '../../../layouts/dashboardlayout'

const Contacts = ({ params }) => {
  const [singleCampaignContactData, setSingleCampaignContactData] = useState(null)
  const [campaignStatData, setCampaignStatData] = useState(null)
  const [campaignStatTable, setCampaignStatTable] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    if (!CheckToken()) {
      navigate('/404')
    }

    const getSingleCampaign = [`api/zoho/campaigns/${params.campaignid}`]
    requestGetWithKey(getSingleCampaign).then(result => {
      if (result.message === '') {
        setSingleCampaignContactData(result.message)
      } else {
        setSingleCampaignContactData(result.message?.data[0])
      }
    })
    const getSingleContactStats = [`api/zoho/campaigns/${params.campaignid}/tasks/stats`]
    requestGetWithKey(getSingleContactStats).then(result => {
      setCampaignStatData(result.message)
      setCampaignStatTable(result.message.data.map(d => {
        return { status: d.status, reason: d.reason, contacts: d.count, percentage: d.percentage.toFixed(2) }
      }))
      setIsLoading(false)
    })
      .catch(error => { setIsLoading(false) })
  }, [])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Winery Portal - Contact</title>
      </Helmet>
      <DashboardLayout>
        <SingleContact singleCampaignContactData={singleCampaignContactData} campaignStatData={campaignStatData} campaignStatTable={campaignStatTable} isLoading={isLoading} />
      </DashboardLayout>
    </>
  )
}

export default Contacts
