import React, { useEffect, useState } from 'react'
import { Card, CardBody, Button, CardTitle, CardText, Spinner } from 'reactstrap'
import CountUp from 'react-countup';
import Typed from 'react-typed';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import BootstrapTable from 'react-bootstrap-table-next'
import { Link } from "gatsby"
import { dowloadFile, randomMessage, typedDelay } from '../../helpers/utils'
import moment from 'moment'

const SingleContact = ({ singleCampaignContactData, isLoading, campaignStatData, campaignStatTable }) => {
  const [loadingMessage, setLoadingMessage] = useState(null)
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoadingMessage(randomMessage())
  }, [])

  const downloadFile = () => {
    setLoading(true)
    dowloadFile([`api/zoho/campaigns/${singleCampaignContactData.id}/contacts/download`])
    .then(() => setLoading(false))
    .catch(() => setLoading(false))
  }

  const columns = [
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
    },
    {
      dataField: 'reason',
      text: 'Reason',
      sort: true,
    },
    {
      dataField: 'contacts',
      text: 'Contacts',
      sort: true,
    },
    {
      dataField: 'percentage',
      text: '%',
      sort: true,
    },
  ]
  if (isLoading) {
    return (
      <Card className="cardHeight">
        <CardBody className="contactBody cardHeight">
          <CardTitle tag="h5" className="cardCount cardLight">
            {loadingMessage}
          </CardTitle>
          <Typed
            id='noExportOrders'
            strings={[`looking for contacts${typedDelay}.${typedDelay}.${typedDelay}.${typedDelay}.${typedDelay}.`]}
            className="cardSubText cardLight"
            showCursor={false}
            typeSpeed={20}
            loop
          />
        </CardBody>
      </Card>
    )
  } else {
    return (
      <>
        {singleCampaignContactData ? (
          <Card>
            <CardBody>
              <>
                <div className="d-flex flex-row activeContact">
                  <Link to={`/campaigns?id=${singleCampaignContactData?.id}`}>
                    <div className="campaignStatsLink">
                      <FontAwesomeIcon icon={faChevronLeft} className="fs--2" />
                    </div>
                  </Link>
                  <div className="camiagn_Text">
                    <h3>{singleCampaignContactData?.Campaign_Name}</h3>
                    <p>{singleCampaignContactData?.Status}</p>
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className="todayDate">
                    {singleCampaignContactData?.Status === 'Active' ? (
                      <p>
                        {moment(singleCampaignContactData?.Start_Date).format('DD/MM/YYYY')} <span> - {moment().format('DD/MM/YYYY')}</span>
                      </p>
                    ) : (
                      <p>
                        {moment(singleCampaignContactData?.Start_Date).format('DD/MM/YYYY')} - {''}
                        <span>{moment(singleCampaignContactData?.End_Date).format('DD/MM/YYYY')}</span>
                      </p>
                    )}
                  </div>
                </div>
                <div className="text-black text-center">
                  <h1 className="headerContact">Contacts</h1>
                  {campaignStatData ?
                    <div className="d-flex activeStats">
                      <div className="w-25">
                        <CountUp
                          end={campaignStatData.info.total ?? 0}
                          duration={2}
                          format='alphanumeric'
                        />
                        <p>Contacts</p>
                      </div>
                      <div className="w-25">
                        <CountUp
                          end={campaignStatData.info.reached ?? 0}
                          duration={2}
                          format='alphanumeric'
                        />
                        <p>Contacts Reached</p>
                      </div>
                      <div className="w-25">
                        <CountUp
                          end={campaignStatData.info.convertedPercent.toFixed(2) ?? 0}
                          duration={2}
                          format='alphanumeric'
                          suffix='%'
                        />
                        <p>Of Reached Contacts Converted</p>
                      </div>
                      <div className="w-25">
                        <CountUp
                          end={campaignStatData.info.remaining ?? 0}
                          duration={2}
                          format='alphanumeric'
                        />
                        <p>Remaining Callable Contacts ({campaignStatData.info.remainingPercent.toFixed(2)}%)</p>
                      </div>
                    </div>
                    : null}
                  {campaignStatData?.info.total === 0 ? "" :
                    loading ? <Spinner type="grow" style={{ width: '3rem', height: '3rem', color: '#77b204' }} /> : 
                  <Button className="btn btn-vino btn-vino-primary" style={{ marginBottom: '10px', width: '300px' }} onClick={() => downloadFile()} >
                    Export all contacts in Campaign
                  </Button>
                  }
                </div>
              </>
            </CardBody>
            {campaignStatTable && campaignStatTable.length > 0 ?
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={campaignStatTable}
                columns={columns}
                defaultSorted={[
                  {
                    dataField: 'status',
                    order: 'asc',
                  },
                ]}
                defaultSortDirection="asc"
                headerClasses="bs-table-header SingleContacttable"
                rowClasses="bs-table-row SingleContacttable"
              />
              : <BootstrapTable
                bootstrap4
                keyField="id"
                data={[]}
                columns={columns}
                bordered={false}
                defaultSorted={[
                  {
                    dataField: 'Full_Name',
                    order: 'asc',
                  },
                ]}
                noDataIndication="Table is Empty"
                defaultSortDirection="asc"
                headerClasses="bs-table-header SingleContacttable"
                rowClasses="SingleContacttable"
              />}
          </Card>
        ) : (
          <>
            <Card className="zeroHeight">
              <Link to={`/campaign`}>
                <div className="campaignStatsLink zeroCount">
                  <FontAwesomeIcon icon={faChevronLeft} className="fs--2" />
                </div>
              </Link>
              <CardBody className="contactBody">
                <CardTitle tag="h5" className="cardCount cardLight">
                  Oops,
                </CardTitle>
                <CardText tag="p" className="cardSubText cardLight" id="noExportContacts">
                  this campaign doesn't exist
                </CardText>
              </CardBody>
            </Card>
          </>
        )}
      </>
    )
  }
}

export default SingleContact
